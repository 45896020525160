import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import http from "./utils/http";

Vue.use(http);
import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
import "./assets/css/reset.css";

import VueClipboard from "vue-clipboard2";

Vue.use(VueClipboard);
Vue.use(ElementUI, {
  size: "small"
});
import "./permission"; // permission control
import directive from "./directive"; // directive
Vue.use(directive);
import store from "./store";

Vue.config.productionTip = false;
new Vue({
  store,
  router,
  render: h => h(App)
}).$mount("#app");
