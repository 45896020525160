import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);
const routes = [
  {
    path: "/",
    component: resolve => require(["@/views/Home.vue"], resolve),
    children: [
      {
        path: "/",
        name: "/",
        component: resolve => require(["@/views/order/index.vue"], resolve)
      },
      {
        path: "/addNew",
        name: "addNew",
        // meta: { rule: 'an' },
        component: resolve => require(["@/views/order/addNew.vue"], resolve)
      },
      {
        path: "/api",
        name: "api",
        component: resolve => require(["@/views/setting/apipage.vue"], resolve)
      },
      {
        path: "/logList",
        name: "logList",
        component: resolve => require(["@/views/setting/logList.vue"], resolve)
      },
      {
        path: "/user",
        name: "user",
        component: resolve => require(["@/views/setting/user.vue"], resolve)
      },
      {
        path: "/rules",
        name: "rules",
        component: resolve => require(["@/views/setting/rules.vue"], resolve)
      },
      {
        path: "/setting",
        name: "setting",
        component: resolve => require(["@/views/setting/setting.vue"], resolve)
      }
    ]
  },
  {
    path: "/redirect",
    name: "redirect",
    component: resolve => require(["@/views/redirect.vue"], resolve)
  },
  {
    path: "/login",
    name: "login",
    component: resolve => require(["@/views/login/login.vue"], resolve)
  },
  {
    path: "/register",
    name: "register",
    component: resolve => require(["@/views/login/register.vue"], resolve)
  },
  {
    path: "/password",
    name: "password",
    component: resolve => require(["@/views/login/password.vue"], resolve)
  },
  {
    path: "/agreement",
    name: "agreement",
    component: resolve => require(["@/views/login/agreement.vue"], resolve)
  },
  {
    path: "/invite", //邀请用户
    name: "invite",
    component: resolve => require(["@/views/login/invite.vue"], resolve)
  },
  {
    path: "/404",
    component: () => import("@/views/error/404"),
    hidden: true
  },
  {
    path: "*",
    redirect: "/404",
    hidden: true
  }
];
// 防止连续点击多次路由报错
let routerPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return routerPush.call(this, location).catch(err => err);
};
const router = new VueRouter({
  mode: "history", // 去掉url中的#
  routes
});

export default router;
