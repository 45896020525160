import router from './router'
import store from './store'

router.beforeEach((to, from, next) => {
  let roles = store.getters.roles
  if (to.matched.length === 0) {
    from.path ? next({name: from.name}) : next('/404')
  } else {
    if (to.meta?.rule && roles.indexOf(to.meta.rule) == -1) {
      next('/404')
    } else {
      next()
    }
  }
})

