import axios from "axios";
import router from "./../router";
import qs from "qs";
import {MessageBox, Message} from "element-ui";
import {getToken, removeToken} from "@/utils/auth";
import JSONBIG from "json-bigint";

export default {
  install: Vue => {
    Vue.prototype.$axios = axios;
    // axios.defaults.withCredentials = true
    // js返回进度丢失
    axios.defaults.transformResponse = [
      function (data) {
        const json = JSONBIG({
          storeAsString: true
        });
        const res = json.parse(data);
        return res;
      }
    ];
    axios.defaults.timeout = 3000000; //请求超时
    const no_login_code = -1; //未登录返回的状态码
    const success_code = 1; //成功返回的状态码
    const err_code = 0; //失败返回的状态码
    axios.defaults.baseURL = process.env.VUE_APP_BASE_API;
    Vue.prototype.httpPath = process.env.VUE_APP_BASE_API;
    Vue.prototype.wsPath = ""; //WebSocket
    
    Vue.prototype.$get = params => {
      const config = {
        headers: {
          "Accept-Language": "en-us"
        }
      };
      axios
        .get(params.url, {
          params: Object.assign(
            {},
            params.data,
            getToken() ? {token: getToken()} : "",
            config
          )
        })
        .then(res => {
          if (res.data.code == no_login_code) {
            router.replace("login");
            // Message.closeAll()
            // Message.error('Not logged in');
            // removeToken()
            // setTimeout(() => {
            //     Message.closeAll()
            //     router.replace('login')
            // }, 1500);
          } else if (res.data.code == success_code) {
            if (params.success) params.success(res.data);
          } else if (res.data.code == err_code) {
            if (params.tip) {
              params.tip("val");
              Message({
                message: res.data.msg,
                type: "error"
              });
            } else if (params.tips) {
              params.tips("vals");
            }
          }
        })
        .catch(err => {
          console.log("err", err);
          if (err.response.status == "404") {
            Message({message: "error:404", type: "error"});
          } else if (err.response.status == "500") {
            params.tip("val");
            Message({message: "errpr:500", type: "error"});
          } else if (params.fail) params.fail(err);
        });
    };
    
    Vue.prototype.$post = params => {
      let $axios;
      let config = {};
      if (params.upload) {
        config = {
          headers: {
            "Content-Type": "multipart/form-data",
            // "Content-Type": "application/x-www-form-urlencoded",
            "Accept-Language": "en-us"
          }
        };
        $axios = axios.post(params.url, params.data, config);
      } else if (params.noToken) {
        config = {
          headers: {
            "Accept-Language": "en-us"
          }
        };
        let data = qs.stringify(Object.assign({}, params.data));
        $axios = axios.post(params.url, data, config);
      } else {
        config = {
          headers: {
            "Accept-Language": "en-us"
          }
        };
        let data = qs.stringify(
          Object.assign(
            {},
            params.data,
            getToken() ? {token: getToken()} : {}
          )
        );
        $axios = axios.post(params.url, data, config);
      }
      
      $axios
        .then(res => {
          if (res.data.code == no_login_code) {
            router.replace("login");
            // Message.closeAll()
            // Message.error('Not logged in');
            // removeToken()
            // setTimeout(() => {
            //     Message.closeAll()
            //     router.replace('login')
            // }, 1500);
          } else if (res.data.code == success_code) {
            if (params.success) params.success(res.data);
          } else if (res.data.code == err_code) {
            if (params.tip) {
              params.tip("val");
              Message({message: res.data.msg, type: "error"});
            } else if (params.tips) {
              params.tips("vals");
            }
          } else if (res.data.code == 2) {
            if (params.showtip) {
              params.showtip(res.data);
            }
          }
        })
        .catch(err => {
          console.log("err", err);
          if (err.response.status == "404") {
            Message({message: "err:404", type: "error"});
          } else if (err.response.status == "500") {
            Message({message: "err:500", type: "error"});
          } else if (params.fail) params.fail(err);
        });
    };
  }
};
